<template>
    <div class="mini_game_bet">
        <div class="mini_game">
            <div class="m1 dmr"  style="margin-top: 10px">
                <!--        <div class="mini_fram">-->
                <!--          <leisure-game-ifram-view>-->
                <!--            <iframe id="jw-gamefram" scrolling="no" frameborder="0"-->
                <!--                    src="https://www.pandagame.site/pandacoin"></iframe>-->
                <!--          </leisure-game-ifram-view>-->
                <!--        </div>-->
                <div class="suresadali_cont" style="">
                    <div class="frm">
                        <iframe src="https://www.pandagame.site/pandacoin" style="width: 1742px;height: 900px;"
                                scrolling="no" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
            <div class="m2">
                <div class="game_tab">
                    <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
                    <leisure-timer-comp
                            ref="ltimer"
                            :leisure-game-info="leisureGameInfo"
                            @timerStop="timerStop" @refreshTimer="refreshTimer">
                        <span slot="title" class="text-orange"><i class="fa fa-star"></i>판다코인</span>
                    </leisure-timer-comp>

                    <!--선택부분-->
                    <div class="mini_bet_box">
                        <div class="mg">
                            <span class="t">앞/뒤</span>
                            <div class="mg_btn b2 bg-blue"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_PANDAGAME_COIN_NORMAL && selectedInfo.selectedWay === 1}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_PANDAGAME_COIN_NORMAL,leisureGameInfo.leisureGame.id,1, lmConfig.odds1, lmConfig, '앞')">
                                <div class="r rblue">
                                    <span class="n">앞</span>
                                    <span class="b">{{lmConfig.odds1}}</span>
                                </div>
                            </div>
                            <div class="mg_btn b2 bg-red"
                                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_PANDAGAME_COIN_NORMAL && selectedInfo.selectedWay === 2}"
                                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_PANDAGAME_COIN_NORMAL,leisureGameInfo.leisureGame.id,2,  lmConfig.odds2, lmConfig, '뒤')">
                                <div class="r rred">
                                    <span class="n">뒤</span>
                                    <span class="b">{{lmConfig.odds2}}</span>
                                </div>
                            </div>
                        </div>


                    </div>

                    <!--파워볼 베팅카트-->
                    <leisure-bet-cart-comp
                            ref="betCart" :kind="kind"
                            :selected-info="selectedInfo">
                        판다코인
                    </leisure-bet-cart-comp>


                </div>
            </div>
            <div class="m3">
                <!--최근 배팅내역-->
                <leisure-lately-bet-list-comp
                        ref="latelyBetList"
                        :kind="kind"></leisure-lately-bet-list-comp>

            </div>


        </div>
    </div>
</template>

<script>
    import SubTitle from "../../../components/SubTitle";
    import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
    import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
    import leisureConst from "../../../common/leisureConst";
    import sportsConst from "../../../common/sportsConst";
    import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
    import {leisureGameMixin} from "../../../common/mixin";
    import {getLeisureGameAttributeConfig} from "../../../network/leisureRequest";
    import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
    import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
    import LeisureGameLinks from "../LeisureGameLinks";
    import LeisureGameIframView from "../LeisureGameIframView";
    import LeisureCompGameLinks from "../LeisureCompDonghangGameLinks";
    import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

    export default {

        name: "PandaGameCoin",
        mixins: [leisureGameMixin],
        components: {
            LeisureCompGameLinks,
            LeisureGameIframView,
            LeisureGameLinks,
            LeisureGameView,
            LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle
        },
        data() {
            return {
                kind: leisureConst.LEISURE_KIND_PANDAGAME_COIN,

            }
        },

        methods: {
            initLeisureGameArttributeConfig() {
                getLeisureGameAttributeConfig().then(res => {
                    if (res.data.success) {
                        this.attrConfig = res.data.data

                        //배당설정
                        this.lmConfig = this.attrConfig.find(attr => {
                            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_PANDAGAME_COIN_NORMAL)
                        })
                    }
                })
            }
        },
        created() {
            this.initKindConfig(this.kind)
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_PANDA);
            this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
        },
    }
</script>

<style scoped>
    .suresadali_cont {
        overflow: hidden;
        width: 100%;
    }

    .suresadali_cont .frm {
        width: 100%;
        transform: scale(0.47);
        transform-origin: 0px 0px 0px;
    }

    @media screen and (max-width: 1024px) {
        .suresadali_cont {
            overflow: hidden;
            width: 420px;
            height: 241px;
        }
        .dmr{
            height: 240px!important;
            min-height: 240px!important;
        }

        .suresadali_cont .frm {
            width: 100%;
            transform: scale(0.2);
            transform-origin: 0px 0px 0px;
        }
    }

    @media screen and (max-width: 912px) {

    }

    @media screen and (max-width: 820px) {

    }

    @media screen and (max-width: 768px) {
        .suresadali_cont {
            overflow: hidden;
            width: 420px;
            height: 241px;
        }

        .suresadali_cont .frm {
            width: 100%;
            transform: scale(0.2);
            transform-origin: 0px 0px 0px;
        }
    }

    @media screen and (max-width: 540px) {
        .suresadali_cont {
            overflow: hidden;
            width: 400px;
            height: 241px;
        }

        .suresadali_cont .frm {
            width: 100%;
            transform: scale(0.21);
            transform-origin: 0px 0px 0px;
        }
    }

    @media screen and (max-width: 414px) {
        .suresadali_cont {
            overflow: hidden;
            width: 400px;
            height: 241px;
        }

        .suresadali_cont .frm {
            width: 100%;
            transform: scale(0.221);
            transform-origin: 0px 0px 0px;
        }
    }

    @media screen and (max-width: 393px) {
        .suresadali_cont {
            overflow: hidden;
            width: 380px;
            height: 241px;
        }

        .suresadali_cont .frm {
            width: 100%;
            transform: scale(0.22);
            transform-origin: 0px 0px 0px;
        }
    }

    @media screen and (max-width: 390px) {
        .suresadali_cont {
            overflow: hidden;
            width: 385px;
            height: 241px;
        }

        .suresadali_cont .frm {
            width: 100%;
            transform: scale(0.22);
            transform-origin: 0px 0px 0px;
        }
    }

    @media screen and (max-width: 375px) {
        .suresadali_cont {
            overflow: hidden;
            width: 370px;
            height: 241px;
        }

        .suresadali_cont .frm {
            width: 100%;
            transform: scale(0.21);
            transform-origin: 0px 0px 0px;
        }
    }

    @media screen and (max-width: 360px) {
        .suresadali_cont {
            overflow: hidden;
            width: 350px;
            height: 241px;
        }

        .suresadali_cont .frm {
            width: 100%;
            transform: scale(0.2);
            transform-origin: 0px 0px 0px;
        }
    }

</style>